$scroll-reveal--transition-duration: 0.6s;
$scroll-reveal--transition-timing: cubic-bezier(0.215, 0.61, 0.355, 1);
$scroll-reveal--from-top-amount: -60px;
$scroll-reveal--from-bottom-amount: 60px;
$scroll-reveal--from-left-amount: -60px;
$scroll-reveal--from-right-amount: 60px;
$scroll-reveal--scale-up-amount: 0.95;
$scroll-reveal--scale-down-amount: 1.05;
$scroll-reveal--rotate-from-left-amount: -45deg;
$scroll-reveal--rotate-from-right-amount: 45deg;
