.responsiveContainer {
    position: relative;
    padding-bottom: 56%;
    height: 0;
    overflow: hidden;
  }
  
  .responsiveContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }